import React from 'react'
import './avatar.css'

export function Avatar () {
  return (
    <div className='avatar-container'>
      <div className='avatar'>
        <a href='mailto:sup@ceddy.org'>
          <img src='https://www.gravatar.com/avatar/cfd6094081678e8efc4096c323d58a94.jpg?s=500' alt='Skytsunami' />
        </a>
      </div>
    </div>
  )
}
