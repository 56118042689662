import React from 'react'
import './grid.css'
import { Avatar } from '../avatar/Avatar'
import WakaTimeEmbedLanguages from '../wakatime/embedLanguages'
import WakaTimeEmbedActivity from '../wakatime/embedActivity'
import { SocialFooter } from '../social/SocialFooter'

export function GridView() {
  return (
    <div className='grid-container'>
      <div className='grid'>
        <div className=' item-a'>
          {/* SVG */}
          <Avatar />
          <svg viewBox="0 0 240 80" xmlns="http://www.w3.org/2000/svg">
            <style dangerouslySetInnerHTML={{ 
              __html: `
              .small { font: bold 13px sans-serif; }
              .heavy { font: bold 20px serif; fill: #5199d4; font-family: -apple-system, system-ui, sans-serif; }
              .Rrrrr { font: italic 40px sans-serif; fill: red; }
               ` }} />
            <text x={20} y={35} className="small">🇨🇦</text>
            <text x={40} y={35} className="heavy">AlienTyperShit 🚀🚀🚀</text>
            {/* <text x={55} y={55} className="small">👁</text>
            <text x={65} y={55} className="Rrrrr">!</text> */}
          </svg>
        </div>
        <div className='flex justify-center items-center item item-b'>
          <div className='w-100 bg-white'>
            <WakaTimeEmbedActivity />
          </div>
        </div>
        <div className='flex justify-center items-center item item-c'>
          <div className='w-100'>
            <WakaTimeEmbedLanguages />
          </div>
        </div>
        {/* <div className='flex justify-center pv4 items-center item item-d'>
          <div className='w-80 tc'>
            <p className='b ttu f6 sans-serif'>Idee</p>
            <h1 className='playfair b lh-title f3 mv0 pv3'>Mondadori e Rizzoli nel 2050</h1>
            <p className='lora'><span className='i'>Giulia Iannuzzi</span> – p. 151</p>
          </div>
        </div> */}
        <div className='flex justify-center pv4 items-center item item-e'>
          {/* <div className='w-80 tc'>
            <p className='b ttu f6 sans-serif'>Idee</p>
            <h1 className='playfair b lh-title f3 mv0 pv3'>Contro<br />Tondelli</h1>
            <p className='lora'><span className='i'>Andrea Gentile</span> – p. 150</p>
          </div> */}
          <SocialFooter />
        </div>
      </div>
    </div>

  )
}
