import React from 'react'

export function WakaTimeEmbedActivity () {
  return (
    <figure>
      <embed src='https://wakatime.com/share/@ceddy/c183b175-3bd9-4512-b902-4a047d43bffe.svg' />
    </figure>
  )
}

export default WakaTimeEmbedActivity
